import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Error messages can be hard to read. Some errors are just impossible to understand. This is how I felt after getting a failure on CI for opsdroid docs.`}</p>
    <p>{`The error message that I mean said `}<inlineCode parentName="p">{`Inline interpreted text or phrase reference start-string without end-string.`}</inlineCode>{`  and here is the error in all its glory! `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`Warning, treated as error:
 [108](https://github.com/opsdroid/opsdroid/runs/941790940#step:5:108) 
/home/runner/work/opsdroid/opsdroid/opsdroid/connector/twitch/__init__.py:docstring of opsdroid.connector.twitch.ConnectorTwitch.twitch_webhook_handler:7:Inline interpreted text or phrase reference start-string without end-string.
`}</code></pre>
    <p>{`After reading through the error, it didn't make much sense. Perhaps, I forgot to close a sentence with a period?`}</p>
    <h2 {...{
      "id": "some-context",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#some-context",
        "aria-label": "some context permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Some Context`}</h2>
    <p>{`We migrated to `}<a parentName="p" {...{
        "href": "https://www.sphinx-doc.org"
      }}>{`Sphinx`}</a>{` to generate our documentation. `}</p>
    <p>{`The great thing about Sphinx is that you can use your `}<a parentName="p" {...{
        "href": "https://www.python.org/dev/peps/pep-0257/#what-is-a-docstring"
      }}>{`docstrings`}</a>{`  to write your documentation, so you don't have to re-write the docs twice.`}</p>
    <p>{`Opsdroid docstrings are pretty good; we aim to give a lot of information to make it easier for new contributors and new coders to understand the codebase faster.`}</p>
    <p>{`Before Sphinx, we used to write the docstrings, then write the documentation in markdown. This means that we would repeat the process of writing the documentation. We spent too long on it.`}</p>
    <p>{`My PR that adds a Twitch Connector to opsdroid uses the docstrings as the main documentation;  I tried to highlight code like you would do in markdown - by using the \`\`\`.`}</p>
    <h2 {...{
      "id": "back-to-the-error",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#back-to-the-error",
        "aria-label": "back to the error permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Back to the error`}</h2>
    <p>{`After looking for the missing punctuation, everything seemed to be in order, but the docs kept failing.`}</p>
    <p>{`Luckily another maintainer spotted the issue and shown me how to quickly fix the problem. `}</p>
    <p>{`The problem was that in RST back-ticks (\`\`\`)  are (or can be) used as a reference. To highlight a piece of text, you need to use double back-ticks and finish off with an empty space.`}</p>
    <p>{`So what you would normally do in markdown won't work in RST.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`This is my awesome \`code\`.
`}</code></pre>
    <p>{`This code needs to be written like this instead:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`This is my awesome \`\`code\`\` .
`}</code></pre>
    <p>{`As you can see, understanding the error is hard, and if you never worked with RST you will probably be as puzzled as me. Luckily, `}<a parentName="p" {...{
        "href": "https://github.com/Cadair"
      }}>{`Cadair`}</a>{` knew about it and helped me solve this issue!`}</p>
    <p>{`After making the changes, CI passed and everything worked as expected.`}</p>
    <p>{`I hope this helps you if you ever encounter this error or if you are trying to highlight a piece of text using Sphinx, but is not working.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      